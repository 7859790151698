/** This file has been adapted from the original file supplied with 'react-calendar' found in common/react-calendar.css.
* 'react-calendar' does not support css-modules, it also does not provide a fine grained interface to style 
* the calendar.
* All the styles have to be marked as global due to the incompatibility with css-modules and the insufficient style api
*/
.react-calendar {
  width: 100%;
  font-family: "Open SansRegular";
}
.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: underline;
  font-weight: bold;
}
.react-calendar__year-view__months__month {
  font-size: medium;
}
.react-calendar__month-view__days__day {
  font-family: "Open SansRegular";
}
.react-calendar__month-view__weekdays__weekday {
  font-size: small;
}
.react-calendar__month-view__days__day--weekend {
  color: #38A169;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #CBD5E0;
}
.react-calendar__tile--active {
  background: #276749;
  color: #FFFFFF;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #276749;
  color: #FFFFFF;
}
.react-calendar__navigation__next-button, .react-calendar__navigation__prev-button {
  flex-grow: 1;
}
