.stripe-checkout__container____3tgIk {
  margin-bottom: calc(5 * 8px);
  position: relative;
}
.stripe-checkout__smallLabel____5KHkq {
  position: absolute;
  top: -6px;
  left: 8px;
  padding: 0 8px;

  color: #276749;
  background-color: #FFFFFF;

  font-size: 12px;
  line-height: 16px;
}
.stripe-checkout__stripeInput____2AGP7 {
  min-height: calc(7 * 8px);
  padding: calc(2 * 8px);
  width: 100%;

  border: 1px solid #2D3748;
  border-radius: 4px;
}
.stripe-checkout__stripeInputEmpty____1Sa0c input::-webkit-input-placeholder {
  color: #FFFFFF;
}
.stripe-checkout__stripeInputEmpty____1Sa0c input::-moz-placeholder {
  color: #FFFFFF;
}
.stripe-checkout__stripeInputEmpty____1Sa0c input:-ms-input-placeholder {
  color: #FFFFFF;
}
.stripe-checkout__stripeInputEmpty____1Sa0c input::-ms-input-placeholder {
  color: #FFFFFF;
}
.stripe-checkout__stripeInputEmpty____1Sa0c input::placeholder {
  color: #FFFFFF;
}
.stripe-checkout__stripeInputComplete____UrrwO {
  color: #276749;
}
.stripe-checkout__stripeInputInvalid____1x9Fy {
  color: #C53030;
}
.stripe-checkout__errorAlert____3NYJc {
  min-height: calc(7 * 8px);
  color: #C53030;
  font-weight: bold;
  background-color: #FEB2B2;
  border-radius: 4px;
  padding: calc(2 * 8px);
  margin-bottom: calc(7 * 8px);
}
.stripe-checkout__errorMessage____1PCEm {
  font-size: 12px;
  color: #C53030;
  padding-left: calc(2 * 8px);
  position: absolute;
}
.stripe-checkout__checkout____itCTk {
  margin-top: calc(3 * 8px);
}
