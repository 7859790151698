.select-question__container____328zM {
  padding: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px dashed #276749;
  background-color: #A0AEC0;
}

.select-question__questionText____1XPdc {
  margin-bottom: calc(2 * 8px);
}

.select-question__hintText____3jWnl {
  margin-bottom: calc(2 * 8px);
}

.select-question__option____74Rb6 {
  margin-top: calc(2 * 8px);
}

.select-question__otherTextInput____2VImu {
  margin-top: calc(2 * 8px);
}

.select-question__image____1MCZI {
  width: 100%;
}
