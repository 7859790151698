.public-header__container____REVGj {
  padding: 8px 0;

  display: flex;
  align-items: center;
  height: calc(12 * 8px);
  text-align: left;
}
.public-header__loginButton____2JqG2 button {
  color: #2D3748 !important;
}
.public-header__homeIcon____1yZ8t > div {
  margin-left: 0;
  line-height: normal;
}
.public-header__terms____169Xi {
  text-align: right;
}
.public-header__headerNavigation____pqRcp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.public-header__headerItem____2Igw0:hover {
  -webkit-text-decoration: underline #276749;
          text-decoration: underline #276749;
}
@media (min-width: 1024px) {
  .public-header__headerNavigation____pqRcp {
    flex-direction: row;
  }
}
