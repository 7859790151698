/* Common styles for fonts generated through icomoon */
@font-face {
  font-family: "krodok-icons";
  src: url(/static/media/krodok-icons.1bfc6d96.eot);
  src: url(/static/media/krodok-icons.1bfc6d96.eot#iefix) format("embedded-opentype"),
    url(/static/media/krodok-icons.97661605.ttf) format("truetype"),
    url(/static/media/krodok-icons.3fc8c1bf.woff) format("woff"),
    url(/static/media/krodok-icons.8a5211e4.svg#krodok-icons) format("svg");
  font-weight: normal;
  font-style: normal;
}
.krodok-icons-common__icon____1oiaQ {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "krodok-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.krodok-icons-common__xlarge____3ro17 {
  font-size: 128px;
}
.krodok-icons-common__large____2F9Mw {
  font-size: 64px;
}
.krodok-icons-common__medium____1FBaz {
  font-size: 48px;
}
.krodok-icons-common__small-medium____203nk {
  font-size: 40px;
}
.krodok-icons-common__small____v4FMR {
  font-size: 32px;
}
.krodok-icons-common__xsmall____ph6YY {
  font-size: 24px;
}
.krodok-icons-common__xxsmall____2z0Rv {
  font-size: 16px;
}
.krodok-icons-common__primary____2CANz {
  color: #276749;
}
.krodok-icons-common__black____2vLZT {
  color: #000000;
}
.krodok-icons-common__green____2NUGs {
  color: #00a300;
}
.krodok-icons-common__blue____1fw7f {
  color: #1700f8;
}
.krodok-icons-common__orange____1a8EO {
  color: #ec5f00;
}
.krodok-icons-common__white____2Rxs2 {
  color: #FFFFFF;
}
.krodok-icons-common__clickable____3px2U {
  cursor: pointer;
}
