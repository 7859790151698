.questions-list__question____1JaK7 {
  margin-top: calc(10 * 8px);
}
.questions-list__question____1JaK7:not(:last-child) {
  min-height: calc(100vh - calc(10 * 8px));
}
@media (min-width: 600px) {
  .questions-list__question____1JaK7 {
    margin-top: calc(20 * 8px);
  }

  .questions-list__question____1JaK7 {
    min-height: calc(100vh - calc(20 * 8px));
  }
}
.questions-list__nextButton____1ws-L {
  margin-top: calc(4 * 8px);
  display: flex;
  flex-direction: column;
}
.questions-list__secondaryButtonNext____d6JOE {
  margin-top: calc(2 * 8px);
}
