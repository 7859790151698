.about-us-page__section____1ndx_ {
  padding-bottom: calc(4 * 8px);
}
.about-us-page__highlight____34C0r {
  color: #276749;
}
.about-us-page__container____1CgMT {
  padding-bottom: 30px;
}
.about-us-page__summary____1LkxO {
  padding-bottom: 30px;
}
.about-us-page__pageTitle____3RfcX {
  padding-bottom: 30px;
}
.about-us-page__container____1CgMT {
  padding-top: calc(6 * 8px);
  padding-bottom: calc(12 * 8px);
}
.about-us-page__questionSection____3Nl1K {
  padding-top: calc(2 * 8px);
  padding-bottom: calc(2 * 8px);
}
.about-us-page__quote____3RGRQ {
  font-style: italic;
}
.about-us-page__teamMemberProfile____1Pius {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: calc(2 * 8px);
}
.about-us-page__teamMemberProfile____1Pius > img {
  margin-right: calc(2 * 8px);
  border-radius: 100%;
}
.about-us-page__teamMemberBio____2JV7j {
  display: flex;
  flex-direction: column;
}
.about-us-page__teamMemberName____2Fydm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.about-us-page__teamMemberName____2Fydm > * {
  padding-right: calc(2 * 8px);
}
/* Linked in Button styling */
.about-us-page__faLinkedin____1LZJQ {
  font: normal normal normal 14px/1 FontAwesome;
  padding: 4px 20px;
  font-size: 2rem;
  text-align: center;
  text-decoration: none;
  background: #007bb5;
  color: white;
}
.about-us-page__faLinkedin____1LZJQ::hover {
  opacity: 0.8;
}
.about-us-page__faLinkedin____1LZJQ::before {
  content: "\f0e1";
}
.about-us-page__employeeImage____2yuZ3 {
  width: 150px;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: -17px;
     object-position: -17px;
}
@media (min-width: 1024px) {
  .about-us-page__teamMemberName____2Fydm {
    flex-direction: row;
    align-items: center;
  }
}
