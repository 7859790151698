/**
 * Defines font related classes and loads the base font stack.
 *
 * NOTE: Loading this file has global effect. This is intentional so HTML tags
 * can be used semantically and be styled consistently. As such, only specify
 * CSS properties that are truly part of the base font stack of the app.
 */
/** Load the base font stack */
html > body,
select,
li,
input,
textarea {
  font-family: "Open SansRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-size: 18px;
  line-height: 24px;
  color: #2D3748;
}
i,
.fonts__italic____30DNZ {
  font-family: "Open SansItalic";
  font-style: italic;
}
.fonts__boldItalic____2PQC6 {
  font-family: "Open SansBoldItalic";
  font-style: italic;
}
.fonts__semibold____2LNYj,
.fonts__semiBold____1ULVD,
.fonts__bold____KqBz8,
b {
  font-family: "Open SansBold";
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
h1 {
  font-size: 34px;
  line-height: 48px;
}
h2 {
  font-size: 24px;
  line-height: 40px;
}
h3 {
  font-size: 18px;
  line-height: 24px;
}
h4 {
  font-size: 14px;
  line-height: 24px;
}
.fonts__hero____2b2qr {
  font-size: 48px;
  line-height: 64px;
  font-weight: bold;
}
.fonts__large____28fxa {
  font-size: 24px;
  line-height: 40px;
}
.fonts__small____31I51 {
  font-size: 12px;
  line-height: 16px;
}
button,
.fonts__button____1JUDy {
  font-family: "Open SansBold";
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}
caption,
.fonts__caption____4QfnQ {
  font-size: 12px;
  line-height: 24px;
}
.fonts__error____2WOlM {
  color: #C53030;
}
p:not(:last-child),
ul:not(:last-child),
ol:not(:last-child) {
  margin-bottom: calc(2 * 8px);
}
sup {
  font-size: 12px;
  vertical-align: super;
}
