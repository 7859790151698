.how-does-this-work-page__section____1YXd8 {
  padding-bottom: calc(4 * 8px);
}
.how-does-this-work-page__highlight____20aC8 {
  color: #276749;
}
.how-does-this-work-page__container____1sosQ {
  padding-bottom: 30px;
}
.how-does-this-work-page__summary____2HYeh {
  padding-bottom: 30px;
}
.how-does-this-work-page__pageTitle____4RgKw {
  padding-bottom: 30px;
}
.how-does-this-work-page__container____1sosQ {
  padding-top: calc(6 * 8px);
  padding-bottom: calc(12 * 8px);
}
.how-does-this-work-page__questionSection____12XL9 {
  padding-top: calc(2 * 8px);
  padding-bottom: calc(2 * 8px);
}
