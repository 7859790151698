.loan-suite-application-page__ctaContainer____2UTxD {
  margin-top: calc(8 * 8px);
}

.loan-suite-application-page__secondaryButton____3kcRH {
  margin-bottom: 8px;
}

.loan-suite-application-page__pageTitle____YQknl {
  margin-bottom: calc(2 * 8px);
}
