.home-page__details____3pwW_ {
  color: #2D3748;
}
.fonts__hero____2b2qr {
  color: #2D3748;
  font-weight: bold;
  line-height: 48px;
}
.home-page__highlight____1OcXV {
  color: #276749;
}
.home-page__title____NPLrg {
  color: #276749;
}
.home-page__sectionTitle____31XmP {
  text-align: center;
  padding-bottom: calc(5 * 8px);
}
.home-page__sectionImage____1yp8k {
  max-height: 420px;
  -o-object-fit: contain;
     object-fit: contain;
}
.home-page__bold____2I1i0 {
  font-weight: bold;
}
.home-page__greyBackground____3V4Ql {
  background-color: #EDF2F7;
}
.home-page__tertiaryBackground____CiOto {
  background-color: #3182CE;
}
.home-page__fontWhite____3mBrD {
  color: #FFFFFF;
}
.home-page__bodyLarge____w_eVk {
  font-size: 24px;
  line-height: 40px;
}
.home-page__underline____1NlW1 {
  text-decoration: underline;
}
svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 300px;
}
.home-page__panel____3AFJU {
  padding: calc(6 * 8px) 0;
}
.home-page__details____3pwW_ {
  margin-bottom: calc(6 * 8px);
}
.home-page__tiles____6JNU1 {
  padding: calc(2 * 8px) 0;
}
.home-page__contact____26m4n {
  font-size: 12px;
  line-height: 16px;
  padding-left: calc(2 * 8px);
}
.home-page__contactWrapper____1kbRY {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: calc(4 * 8px);
}
.home-page__inlineLink____26cLL {
  font-weight: bold;
  text-decoration: underline;
}
@media (max-width: 600px) {
  .home-page__tiles____6JNU1 > *:first-child {
    margin-bottom: calc(2 * 8px);
  }
}
@media (min-width: 600px) {
  .home-page__panel____3AFJU {
    padding: calc(8 * 8px) 0;
  }
}
@media (min-width: 1024px) {
  .home-page__panel____3AFJU {
    padding: calc(12 * 8px) 0;
  }

  .home-page__sectionTitle____31XmP {
    margin-bottom: calc(4 * 8px);
  }
}
