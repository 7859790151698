.text-question__questionText____qtAtI {
  margin-bottom: calc(2 * 8px);
}

.text-question__hintText____2r2ZC {
  margin-bottom: calc(2 * 8px);
}

.text-question__input____3flQm {
  margin: calc(2 * 8px) 0;
}
