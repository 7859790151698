/*
 * The definitive list of colors to be used in the app. Colors should never
 * be referenced by hex code outside this file. All colors should be represented in
 * the list below.
 *
 * Please name colors with the appropriate prefix to avoid conflicts with
 * native browser colors:
 *
 * All new colors should appear in storybook
 */

/** Loader colours */
