.check-item__checkItem____2Fdf6 {
  margin-top: calc(3 * 8px);
  align-items: flex-start;
  display: flex;
  cursor: pointer;
}

.check-item__checkItemLabel____1dcZi {
  margin-left: 8px;
}

.check-item__disabled____275sw {
  color: #2D3748;
}

.check-item__outlined____3Oxki.check-item__checkItem____2Fdf6 {
  padding: calc(2 * 8px) 8px;
  border: 1px solid #276749;
  border-radius: 4px;
}

.check-item__hintText____3xiWf {
  margin-top: 8px;
}

.check-item__inlineLink____3VRSD {
  color: #276749;
  text-decoration: underline;
}

.check-item__inlineLink____3VRSD:hover {
  color: #276749;
  text-decoration: underline;
}
