.modal__container____1jrT- {
  padding: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px dashed #2D3748;
  background-color: #CBD5E0;
}

.modal__modal____3PgMx {
  width: 320px;
  padding: calc(2 * 8px);

  margin: auto;
  display: flex;
  flex-direction: column;

  border: 1px solid rgb(204, 204, 204);
  background-color: #FFFFFF;
  border-radius: 4px;
  outline: none;
}

.modal__overlay____jW6xF {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  padding: 10vh 0;
  justify-content: center;
  align-items: center;

  background-color: rgb(0, 0, 0, 0.7);
  overflow: scroll;
  z-index: 9999;
}

.modal__closeIcon____YRTOJ {
  align-self: flex-end;
}

.modal__content______DXK {
  margin-top: calc(2 * 8px);
}

.modal__contentText____2D0fF {
  text-align: center;
}

.modal__buttons____2L0bH {
  margin-top: calc(4 * 8px);
  margin-bottom: calc(2 * 8px);
}

.modal__button____2U7PM + .modal__button____2U7PM {
  margin-top: calc(2 * 8px);
}

.modal__center____1q1NC {
  text-align: center;
}

.modal__alert____34-F8 {
  text-align: center;
}

.modal__alert____34-F8 .modal__icon____TxZia {
  margin-bottom: 8px;
}

.modal__iconText____3Ln4F {
  margin-bottom: calc(4 * 8px);
}
