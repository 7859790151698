/*
 * IMPORTANT: The spinner size has been precomputed to fix an IE alignment issue
 * with nested 'calc' statements. The formula for spinnerSize is `10 * unit`.
 * If the value of 'unit' is changed, please update this value as well.
 */
.button__button____obLer {
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0;
}
.button__button____obLer.button__primary____20IQr {
  color: #FFFFFF;
  background-color: #276749;
}
.button__button____obLer.button__primary____20IQr:disabled {
  background-color: #EDF2F7;
  color: #A0AEC0;
}
.button__button____obLer.button__primary____20IQr:hover:not(:disabled) {
  background-color: #38A169;
}
.button__button____obLer.button__primary____20IQr:active:not(:disabled) {
  background-color: #276749;
}
.button__button____obLer.button__secondary____2UoDq {
  border: 2px solid #276749;
  color: #276749;
  background-color: #FFFFFF;
}
.button__button____obLer.button__secondary____2UoDq:disabled,
.button__button____obLer.button__tertiary____3MS37:disabled {
  border-radius: 1px;
  border: 2px solid #CBD5E0;
  color: #CBD5E0;
}
.button__button____obLer.button__secondary____2UoDq:hover:not(:disabled) {
  border-width: 2px;
  background-color: #F0FFF4;
}
.button__button____obLer.button__secondary____2UoDq:active:not(:disabled) {
  border-width: 2px;
  background-color: #F0FFF4;
}
.button__button____obLer.button__tertiary____3MS37 {
  color: #276749;
  background-color: #FFFFFF;
}
.button__button____obLer.button__tertiary____3MS37:hover:not(:disabled) {
  background-color: #F0FFF4;
}
.button__button____obLer.button__tertiary____3MS37:active:not(:disabled) {
  background-color: #276749;
}
.button__button____obLer.button__regular____2akIL {
  min-height: 48px;
  padding: calc(12px - 2 * 2px);
}
.button__button____obLer.button__small____183D0 {
  min-height: 36px;
  padding: calc(6px - 2 * 2px)
    calc(12px - 2 * 2px);
}
.button__button____obLer.button__round____1UpeF {
  color: #FFFFFF;
  background-color: #276749;
  width: auto;
  display: inline-block;
  border-radius: 24px;
  min-height: 40px;
  padding: 8px calc(2 * 8px);
}
.button__button____obLer.button__round____1UpeF:disabled {
  background-color: #2D3748;
  color: #000000;
}
.button__button____obLer.button__round____1UpeF:hover:not(:disabled) {
  background-color: #38A169;
}
.button__button____obLer.button__round____1UpeF:active:not(:disabled) {
  background-color: #276749;
}
.button__button____obLer.button__outline____1MNUE {
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  background-color: transparent;
}
.button__button____obLer.button__outline____1MNUE:hover:not(:disabled) {
  border-width: 2px;
  background-color: colorSilver;
}
.button__button____obLer.button__outline____1MNUE:active:not(:disabled) {
  border-width: 2px;
  background-color: transparent;
}
.button__button____obLer .button__content____1WJEe {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.button__button____obLer .button__contentLabel____2X60D {
  display: flex;
  align-items: center;
}
.button__button____obLer .button__leftIcon____4QDSL {
  margin-right: 8px !important;
  line-height: normal !important;
}
.button__button____obLer .button__rightIcon____1qkGo {
  margin-left: 8px;
  line-height: normal;
}
.button__inProgress____3T0kr .button__content____1WJEe {
  visibility: hidden;
}
.button__spinner____JeG0K {
  position: absolute;
  left: calc(50% - 0.5 * 80px);
  width: 80px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.button__spinner____JeG0K:after {
  content: "";
  border: 8px solid #2D3748;
  border-top: 8px solid #276749;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  -webkit-animation: button__spin____kgIAS 1.5s linear infinite;
          animation: button__spin____kgIAS 1.5s linear infinite;
  display: inline-block;
}
/* Safari */
@-webkit-keyframes button__spin____kgIAS {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes button__spin____kgIAS {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE 11 CSS styles */

  .button__button____obLer.button__regular____2akIL {
    /*
     * IE 11 does not support min-height in a flex container or any of its child elements.
     * See: https://github.com/philipwalton/flexbugs#flexbug-3
     * This led to an issue which saw text in buttons not aligned properly.
     * The workaround as suggested in the write-up is to substitue min-height to a
     * fixed height.
     */
    height: 48px;
    /*
     * IE 11 rendered buttons with a loading spinner off-center inside the button.
     * This fix improves the centering of the spinner along with the fixed bottom
     * margin below.
     */
    display: flex;
    flex-direction: column;
  }

  .button__spinner____JeG0K {
    bottom: 1px;
  }
}
