.style__krodok-icons-logo-full____24G8l:before {
  content: "\e915";
}
.style__krodok-icons-checkbox-off____2-WVw:before {
  content: "\e913";
}
.style__krodok-icons-checkbox-on____2Pjxx:before {
  content: "\e914";
}
.style__krodok-icons-logo____pmbZM:before {
  content: "\e900";
}
.style__krodok-icons-x____20hcJ:before {
  content: "\e901";
}
.style__krodok-icons-plus____13ADo:before {
  content: "\e902";
}
.style__krodok-icons-phone____cvv6I:before {
  content: "\e903";
}
.style__krodok-icons-menu____27nkO:before {
  content: "\e904";
}
.style__krodok-icons-mail____2ivzw:before {
  content: "\e905";
}
.style__krodok-icons-lock____1GR8E:before {
  content: "\e906";
}
.style__krodok-icons-location____3927J:before {
  content: "\e907";
}
.style__krodok-icons-group____2BCzF:before {
  content: "\e908";
}
.style__krodok-icons-file____2LlI4:before {
  content: "\e909";
}
.style__krodok-icons-download____Z6J33:before {
  content: "\e90a";
}
.style__krodok-icons-dot____12PoU:before {
  content: "\e90b";
}
.style__krodok-icons-credit-card____3tUc5:before {
  content: "\e90c";
}
.style__krodok-icons-circle-tick____2sO_9:before {
  content: "\e90d";
}
.style__krodok-icons-chevron-up____tchjy:before {
  content: "\e90e";
}
.style__krodok-icons-chevron-down____3r1i9:before {
  content: "\e90f";
}
.style__krodok-icons-building____2gblT:before {
  content: "\e910";
}
.style__krodok-icons-arrow-right____1btDF:before {
  content: "\e911";
}
.style__krodok-icons-arrow-left____wHpwN:before {
  content: "\e912";
}
