.multi-select-question__container____2OOmj {
}

.multi-select-question__questionText____mOgHG {
  margin-bottom: calc(2 * 8px);
  font-size: 20px;
}

.multi-select-question__hintText____3RMYp {
  margin-bottom: calc(2 * 8px);
}

.multi-select-question__customTextInput____219WW {
  margin-top: calc(3 * 8px);
}

.multi-select-question__option____1fsoU {
  margin-top: calc(2 * 8px);
}