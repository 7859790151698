/*
 * The grid containers have horizontal margin equal to half the gutter width,
 * including the first and last container.
 * So we calculate the appropriate additional horizontal padding to achieve the
 * effect of a desired margin on the body.
 */
.page-body__container____1y2XZ {
  background-color: #FFFFFF;
}
/** NOTE: Exported for composing into a container that spans the full grid */
.page-body__gridContent____3hcie {
  padding: 0 calc(16px - 16px / 2);
}
@media (min-width: 600px) {
  .page-body__gridContent____3hcie {
    padding: 0 calc(32px - 16px / 2);
  }
}
@media (min-width: 1024px) {
  .page-body__gridContent____3hcie {
    margin: 0 auto;
    padding: 0 calc(48px - 24px / 2);
    max-width: 1024px;
    width: 100%;
  }
}
/** The content consists of the grid and margin */
.page-body__content____-qMzF {
  flex: 1;
}
