/* Use identical logic used to constrain page body content */
/* See https://css-tricks.com/full-width-containers-limited-width-parents/ */
.full-width__container____7enNS {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
