/* ---------------------------------------------------------------------------------

Only globals and all globals MUST belong here
this file needs to be imported at all entry points, e.g. the app, storybook, test init

This file is split into two sections.

Section 1 contains globals that are meant to be applied across all projects. Changes in this section
should be considered a core change and should also be applied to proto-app (hyphenated to prevent replacement).

Section 2 contains only truly project-wide styles. First consider whether they can belong in just a widget
This section can contain styles that immediately override Section 1 styles.

--------------------------------------------------------------------------------- */

/* --------- Section 1. Reset CSS intended to applied across all projects. ---------*/

* {
  box-sizing: border-box;
}

button,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  font-size: 10px;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button:focus,
input:focus {
  outline: 0;
}

button {
  cursor: pointer;
  background: inherit;
}

/* widget specific (most likely). Could be 'global reset css', if so, leave in this file */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Fixes rounded edges on ios text inputs. */

input[type="text"],
input[type="number"],
input[type="tel"] {
  -webkit-appearance: none;
  border-radius: 0;
}

/* --------- Section 2. Project specific globals ---------*/

/* ---------
  Section 2.X Fonts should only contain:
    @font-face src
    font-family
    font-weight
    font-style
    font-smoothing
    font-size
    text-decoration
--------- */

@font-face {
  font-family: "Open SansRegular";
  src: url(/static/media/opensans-regular.df2d6f2b.woff2) format("woff2"),
    url(/static/media/opensans-regular.46d1ea18.woff) format("woff"),
    url(/static/media/opensans-regular.fb1e6b30.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open SansBold";
  src: url(/static/media/opensans-bold.a21531ec.woff2) format("woff2"),
    url(/static/media/opensans-bold.3e1c750b.woff) format("woff"),
    url(/static/media/opensans-bold.2ee4fbea.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open SansItalic";
  src: url(/static/media/opensans-italic.20ab65ce.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Open SansBoldItalic";
  src: url(/static/media/opensans-bolditalic.ac92bed2.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

html > body,
select {
  font-family: "Open SansRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  font-size: 16px;
}

strong {
  font-family: "Open SansBold";
}

a {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-family: "Open SansBold";
  font-size: 32px;
}

h2 {
  font-family: "Open SansBold";
  font-size: 28px;
}

h3 {
  font-family: "Open SansBold";
  font-size: 24px;
}

h4 {
  font-family: "Open SansBold";
  font-size: 20px;
}

h5 {
  font-family: "Open SansBold";
  font-size: 16px;
}

h6 {
  font-family: "Open SansBold";
  font-size: 12px;
}

button {
  font-family: "Open SansRegular";
  font-size: 16px;
}

li {
  line-height: 2;
}

/* ---------
  Section 2.X Colors should only contain:
    color
--------- */

html > body,
select {
  color: #000000;
}

a {
  color: inherit;
}

/* ---------
  Section 2.X Cursors should only contain:
    cursor
--------- */

a {
  cursor: pointer;
}
