/** Standard multiple for sizes and spacings */
/** Standard border radius */
/** Standard border width */
/** Breakpoints : Mobile first use `@media (min-width: smBreak)` */
/* Column specifications */
/*
 * IMPORTANT: Column widths are precomputed to address nested 'calc' statements which are
 * not supported in IE 11. If you update the number of columns please also update the
 * column widths with the formulas indicated below.
 */
/* xsColWidth = 100% / xsNumCols */
/* smColWidth = 100% / smNumCols */
/* mdColWidth = 100% / mdNumCols */
/* Page specifications */
