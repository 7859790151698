.text-input__container____wfkQj {
  position: relative;
  padding-top: 8px;
}
.text-input__smallLabel____2tV4m {
  position: absolute;
  top: 0;
  left: 8px;
  padding: 0 8px;

  color: #276749;
  background-color: #FFFFFF;

  font-size: 12px;
  line-height: 16px;
}
.text-input__error____2rrof .text-input__smallLabel____2tV4m {
  color: #C53030;
}
input.text-input__input____27FBG,
textarea.text-input__input____27FBG {
  min-height: 56px;
  padding: calc(2 * 8px);
  width: 100%;

  border: 1px solid #2D3748;
  border-radius: 4px;
}
.text-input__filled____2-Ws3 input.text-input__input____27FBG,
.text-input__filled____2-Ws3 textarea.text-input__input____27FBG {
  border-color: #276749;
}
input.text-input__input____27FBG:focus {
  padding: 0 calc(2 * 8px - 1px);
}
input.text-input__input____27FBG:focus,
textarea.text-input__input____27FBG:focus {
  border: 2px solid #276749;
}
.text-input__error____2rrof input.text-input__input____27FBG {
  padding: 0 calc(2 * 8px - 1px);
}
.text-input__error____2rrof input.text-input__input____27FBG,
.text-input__error____2rrof textarea.text-input__input____27FBG {
  border: 2px solid #C53030;
}
.text-input__errorMessage____1--bC {
  font-size: 12px;
  color: #C53030;
  padding-left: calc(2 * 8px);
}
.text-input__errorIcon____2LuiF {
  position: absolute;
  top: calc(3 * 8px);
  right: 8px;
}
.text-input__disabled____3UQWc input.text-input__input____27FBG,
.text-input__disabled____3UQWc textarea.text-input__input____27FBG {
  border-color: #2D3748;
  background-color: #FFFFFF;
}
.text-input__disabled____3UQWc .text-input__smallLabel____2tV4m {
  color: #2D3748;
}
textarea {
  resize: none;
}
textarea:focus {
  outline: none;
}
.text-input__clickableContainer____lDNfN {
  display: relative;
}
.text-input__dateContainer____jzRE6 .text-input__input____27FBG,
.text-input__dateContainer____jzRE6 .text-input__input____27FBG:focus,
.text-input__error____2rrof .text-input__dateContainer____jzRE6 .text-input__input____27FBG {
  padding-left: calc(5 * 8px);
}
.text-input__dateContainer____jzRE6 input:disabled {
  background: #FFFFFF;
}
.text-input__dateContainer____jzRE6 {
  position: relative;
}
.text-input__icon____1zAYg {
  position: absolute;
  color: #2D3748;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  cursor: pointer;
  /** Fix for mobile rendering issue where the icon is not clickable */
  z-index: 1;
}
.text-input__disabled____3UQWc .text-input__icon____1zAYg {
  cursor: default;
}
.text-input__modalContent____1azFY {
  text-align: center;
}
