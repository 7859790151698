.clickable-tile__containerWrapper____1X5b7 {
  width: 100%;
  border-radius: 8px;
  border: 3px solid #276749;
  box-shadow: 0 4px 4px 0 rgba(22, 112, 65, 0.4);
  cursor: pointer;
  vertical-align: top;
  background-color: #FFFFFF;
  height: 288px;
}
.clickable-tile__containerWrapper____1X5b7:hover {
  box-shadow: 0px 8px 8px rgba(22, 112, 65, 0.4);
}
.clickable-tile__container____2LXjA {
  position: relative;
  padding: calc(4 * 8px) calc(2 * 8px) calc(2 * 8px) calc(2 * 8px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.clickable-tile__containerWrapper____1X5b7:active {
  border-color: #276749;
}
.clickable-tile__icon____2y3rx {
  margin-right: 8px;
}
.clickable-tile__title____1vUIp {
  flex: 1;
}
.clickable-tile__text____1SY1Q {
  margin-top: 8px;
  min-height: 5em;
}
.clickable-tile__cta____3U2u- {
  display: flex;
  justify-content: space-between;
}
.clickable-tile__ctaText____Y1zbl {
  color: #276749;
  font-weight: normal;
  display: flex;
}
.clickable-tile__ctaText____Y1zbl > span {
  margin-left: 8px;
}
@media (min-width: 1024px) {
  .clickable-tile__containerWrapper____1X5b7 {
    height: 248px;
  }
}
@media (min-width: 1440px) {
  .clickable-tile__containerWrapper____1X5b7 {
    height: 264px;
  }
}
