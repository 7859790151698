.informational-question__questionText____2jZdz {
  margin-bottom: calc(2 * 8px);
}

.informational-question__hero____SYu7A {
  font-size: 34px;
  line-height: 64px;
  font-weight: bold;
}

.informational-question__ctaContainer____2mQkN {
  margin-top: calc(6 * 8px);
}
