.multi-text-question__questionText____2Sl51 {
  margin-bottom: calc(2 * 8px);
}

.multi-text-question__hintText____3CKuv {
  margin-bottom: calc(2 * 8px);
}

.multi-text-question__input____sn0DN + .multi-text-question__input____sn0DN {
  margin-top: calc(3 * 8px);
}
