.select-option__option____3l0rK {
  min-height: 48px;
  padding: calc(1.5 * 8px) 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-weight: bold;

  color: #276749;
  cursor: pointer;
  border: 1px solid #276749;
  border-radius: 2px;
}

.select-option__option____3l0rK.select-option__selected____1WXt7 {
  color: #FFFFFF;
  background-color: #276749;
}
