.confirm-page__title_____jVRN {
  display: flex;
  align-items: center;
  padding-bottom: calc(2 * 8px);
}
.confirm-page__title_____jVRN > span {
  margin-right: calc(2 * 8px);
}
li {
  list-style: disc;
  margin-left: calc(4 * 8px);
}
.confirm-page__nextSteps____24qZc {
  padding-top: calc(4 * 8px);
  padding-bottom: calc(6 * 8px);
}
.confirm-page__download____8ZFZV,
.confirm-page__return____1e1iR {
  margin-bottom: calc(5 * 8px);
}
