.public-footer__container____LFDqP {
  background: #2D3748;
  color: #FFFFFF;
}
.public-footer__footerLinks____Fb1Ee {
  min-height: calc(12 * 8px);
  padding-top: calc(4 * 8px);
  align-items: left;
}
.public-footer__footerNavigation____VqjsO {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.public-footer__section____22ZzR li {
  padding-bottom: calc(3 * 8px);
  color: #FFFFFF;
}
.public-footer__termsAndConditions____2oTog,
.public-footer__other____2GLOh {
  margin-bottom: calc(4 * 8px);
}
.public-footer__copyright____z9Gjk {
  font-size: 12px;
}
@media (min-width: 1024px) {
  .public-footer__container____LFDqP {
    text-align: center;
  }

  .public-footer__termsAndConditions____2oTog,
  .public-footer__workplaces____1lT23,
  .public-footer__other____2GLOh {
    margin-bottom: 0;
  }

  .public-footer__footerNavigation____VqjsO {
    flex-direction: row;
  }
}
.public-footer__shareIcons____2rrRV {
  text-align: left;
}
.public-footer__icon____wfOXH:not(:last-child) {
  display: inline;
  margin-right: calc(8 * 8px);
}
@media (min-width: 1024px) {
  .public-footer__shareIcons____2rrRV {
    text-align: left;
  }

  .public-footer__shareIcons____2rrRV > div {
    margin-left: 0;
  }
  .public-footer__icon____wfOXH:not(:last-child) {
    margin-right: calc(2 * 8px);
  }
}
