.review-page__title____2QEb1 {
  margin-top: calc(6 * 8px);
}
.review-page__statement____1kXM_ {
  margin-top: calc(2 * 8px);
}
.review-page__ctaContainer____phttr {
  margin-top: calc(4 * 8px);
}
.review-page__questionList____LGeC2:first-child > * {
  min-height: calc(2 * 8px);
  margin-top: 0;
}
@media (min-width: 600px) {
  .review-page__questionList____LGeC2:first-child > * {
    min-height: calc(2 * 8px);
    margin-top: 0;
  }
}
