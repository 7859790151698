/* Use identical logic used to constrain page body content */
.form-page-layout__page____Rruoo {
  min-height: 100vh;

  background-color: #FFFFFF;
}
.form-page-layout__pageBody____3iJnm {
  background-color: #FFFFFF;
}
.form-page-layout__pageContent____7lD79 {
  min-height: 100vh;
  padding-top: 48px;
  padding-bottom: 88px;

  background-color: #FFFFFF;
}
.form-page-layout__headerContainer____2DDJs {

  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  background-color: #FFFFFF;
}
.form-page-layout__headerContent____3SOOC {
  height: 48px;
}
.form-page-layout__footerBackground____oK_7j {
  z-index: 1;
  background-color: #2D3748;
}
.form-page-layout__footerContainer____UivXS {

  border-top: 1px solid #2D3748;
  background-color: #2D3748;
}
.form-page-layout__footerContent____2aDdW {
  height: 88px;
}
