.page-layout__page____19kR7 {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}
.page-layout__pageBody____1zv1V {
  background-color: #FFFFFF;
}
.page-layout__pageContent____3NZ6K {
  min-height: 100vh;
  background-color: #FFFFFF;
}
.page-layout__headerContainer____3fwoK {

  background-color: #FFFFFF;
}
.page-layout__headerContent____1rqsE {
  height: calc(12 * 8px);
}
.page-layout__footerContainer____2OKrP {

  border-top: 1px solid #2D3748;
  background-color: #2D3748;
}
.page-layout__footerContent____3AoGw {
  height: calc(12 * 8px);
}
.page-layout__footerBackground____K95IZ {
  z-index: 1;
  background-color: #2D3748;
}
.page-layout__goLink____hKw4w {
  text-decoration: underline;
  cursor: pointer;
}
.page-layout__menuIcon____3mbJu {
  position: relative;
  top: 2px;
  margin-left: 10px;
}
/* Dropdown Button */
.page-layout__dropbtn____2IERU {
  cursor: pointer;
  color: white;
  border: none;
  padding: 10px;
}
/* The container <div> - needed to position the dropdown content */
.page-layout__dropdown____3AQAM {
  position: relative;
  display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.page-layout__dropcontent____19izh {
  display: none;
  position: absolute;
  background-color: black;
  width: 100%;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
/* Links inside the dropdown */
.page-layout__dropcontent____19izh a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* Show the dropdown menu on hover */
.page-layout__dropdown____3AQAM:hover .page-layout__dropcontent____19izh {
  display: block;
}
