.responsive-image__container____1aD6g {
  width: 100%;
  height: 100%;
}

.responsive-image__image____12T5h {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/* IE 11 CSS styles */

.responsive-image__ieContainer____145Xd {
  display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .responsive-image__image____12T5h {
    display: none;
  }

  .responsive-image__ieContainer____145Xd {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100%;
  }

  /** Fix object-fit: cover: https://stackoverflow.com/questions/37792720/ie-and-edge-fix-for-object-fit-cover */
  .responsive-image__ieImg____1U2UZ {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
}
